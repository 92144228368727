import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../styles/Navbar.css";
import "../styles/Mobile.css";
import DarkModeToggle from "../context/Darkmode";

function Navbar() {
  // State to control visibility of the navbar
  const [visible, setVisible] = useState(true);
  const [isTop, setAtTop] = useState(true);
  const prevScrollY = useRef(0);
  const scrollThreshold = 10;
  // Function to handle scroll events
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY == 0) {
      setAtTop(true);
      setVisible(true);
    } else if (currentScrollY > prevScrollY.current + scrollThreshold) {
      // Scrolling down beyond the threshold
      setVisible(false);
      setAtTop(false);
    } else if (currentScrollY < prevScrollY.current) {
      // Scrolling up beyond the threshold
      setVisible(true);
      setAtTop(false);
    }

    prevScrollY.current = currentScrollY;
  };

  // useEffect to add and remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`header ${
        visible
          ? isTop
            ? "navbar container ar-section-padding visible hide-border"
            : "navbar container ar-section-padding visible scrolled-bg"
          : "navbar container ar-section-padding hidden"
      }`}
    >
      <div className="ar-logo">
        <span className="jump-letter">A </span>
        <span className="jump-letter">M</span>
        <span className="jump-letter">R </span>
        <span className="jump-letter">I</span>
        <span className="jump-letter">T</span>
      </div>

      <ul className="nav-list web-view">
        <nav>
          <li className="nav-item">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              About
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              Contact
            </NavLink>
          </li>
          <div className="animation start-home"></div>
          <div className="animation-2 start-home"></div>
        </nav>
      </ul>

      <div className="ar-info">
        <DarkModeToggle />
      </div>
    </div>
  );
}

export default Navbar;
