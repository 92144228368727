import React from "react";
import jktechLogo from "../assets/logo/jktech.jpeg";
import erouteLogo from "../assets/logo/eroute.jpeg";
import mLabsLogo from "../assets/logo/mLabs.jpeg";
import { FaTimes } from "react-icons/fa";
const images = {
  jktechLogo,
  erouteLogo,
  mLabsLogo,
};
const WorkHistoryModal = ({ show, handleClose, experience }) => {
  return (
    <div
      className={`modal fade ${show ? "show d-block" : "hidden d-none"}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="workHistoryModalLabel"
      aria-hidden={!show}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header ar-flex-between">
            <h5 className="modal-title ar-highlighted-text" id="workHistoryModalLabel" >
              Experience
            </h5>
            <button type="button" className="transparent-bg-btn" style={{ color: "var(--primary-color)" }}
              onClick={handleClose}
            > <FaTimes /> </button>
          </div>
          <div className="modal-body" style={{ maxHeight: "75vh", overflowY: "auto" }}>
            {experience ? (
              <div className="mb-4">
                <div className="ar-flex ar-gap-10">
                  <div className="">
                    <img
                      src={images[experience.logo]}
                      alt={experience.title}
                      className="modal-logo"
                    />
                  </div>
                  <div className="">
                    <h5 className=" ar-highlighted-text">
                      {experience.title} | {experience.company}
                    </h5>
                    <p>
                      <i>{experience.dateRange}</i>
                    </p>
                  </div>
                </div>

                <p className="ar-light-grey">{experience.description}</p>
                <ul className="ar-light-grey">
                  {experience.detailedDescription.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
                {experience.image && (
                  <img
                    src={experience.image}
                    alt={experience.title}
                    className="img-fluid mt-3"
                  />
                )}
              </div>
            ) : (
              <p>No experience details available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkHistoryModal;
