import React, { useState, useEffect } from "react";
import "../styles/Admin.css";
import apiService from "../widget/api";
import Loader from "../context/Loader";

import deleteIcon from '../assets/icons/red-delete.svg'

function Admin() {
  const [users, setUsers] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await apiService.getUsers();
        setUsers(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    fetchUsers();
  }, []);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem); // Safe to call slice now
  const totalPages = Math.ceil(users.length / itemsPerPage);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  if (!users.length) {
    return <p>No data available.</p>;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    // Customize the format as needed; here's a simple example
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`; 
  }

  return (
    <div className="container">
      {/* Pagination Controls */}
      <div aria-label="Page navigation" className="flex-table-heads">
        <div className="">
        <span className="page-count"> Page count : {users.length} </span> 
        </div>

        <div className="">
        <ul className="ar-pagination">
       
          <li className={`${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => setCurrentPage(currentPage - 1)}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {[...Array(totalPages)].map((_, index) => (
            <li
              key={index + 1}
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(currentPage + 1)}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
        </div>
      </div>
      <div className="" style={{height: '70vh', overflow: 'auto'}}>
      <table>
        <thead>
          <tr>
            <th>Sl/No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Message</th>
            <th>Date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((user, i) => (
            <tr key={user.id}>
              <td>{i + 1}</td>
              <td>{user.firstName}</td>
              <td>{user.phone}</td>
              <td>{user.email}</td>
              <td>{user.message}</td>
              <td>{formatDate(user.date)}</td>
              <td><img src={deleteIcon} alt="delete-icon"/></td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="ar-spacer-hr-sm"></div>
    </div>
  );
}

export default Admin;
