import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // Import react-toggle CSS
import '../styles/Darkmode.css'; // Your custom CSS for additional styles

function DarkModeToggle() {
  const [darkMode, setDarkMode] = useState(() => 
    localStorage.getItem('darkMode') === 'true' || false
  );

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
      localStorage.setItem('darkMode', 'true');
    } else {
      document.body.classList.add('light-mode');
      document.body.classList.remove('dark-mode');
      localStorage.setItem('darkMode', 'false');
    }
  }, [darkMode]);

  return (
    <div className="toggle-container">
      <label className="toggle-label">
        <FaMoon className={`icon-toggle moon-icon ${!darkMode ? 'hidden' : ''}`} />
        <Toggle
          checked={darkMode}
          onChange={() => setDarkMode(!darkMode)}
          icons={false}
        />
        <FaSun className={`icon-toggle sun-icon ${!darkMode ? '' : 'hidden'}`} />
      </label>
    </div>
  );
}

export default DarkModeToggle;
