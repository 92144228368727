import React, { useRef } from 'react';
import gsap from 'gsap';

function Projects() {

  return (
    <div className='ar-flex-center' style={{height:'60vh'}}>
    <h1>404 | Not Found</h1>
  </div>
    
  )
}

export default Projects
