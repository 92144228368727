import React, { useState } from "react";
import "../styles/Home.css";
import { FaBattleNet, FaCircle, FaArrowUp } from "react-icons/fa";
import { SiPrimeng } from "react-icons/si";

import { useNavigate } from "react-router-dom";
import { FaRegHand } from "react-icons/fa6";
import mainImage from "../assets/frontend-dev.png";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import Carousel from "../context/Carousel";

// icons
import angular from "../assets/icons/angular.svg";
import react from "../assets/icons/react.svg";
import ionic from "../assets/icons/ionic.svg";
import Bootstrap from "../assets/icons/bootstrap.svg";
import html from "../assets/icons/html.svg";
import css from "../assets/icons/css.svg";
import GSAP from "../assets/icons/gsap.svg";
import JavaScript from "../assets/icons/j.svg";
import TypeScript from "../assets/icons/typescript.svg";
import cypress from "../assets/icons/cypress.svg";
import karma from "../assets/icons/karma.svg";
import devops from "../assets/icons/devops.svg";
import eclipse from "../assets/icons/eclipse.svg";
import vsCode from "../assets/icons/visual-studio.svg";
import figma from "../assets/icons/figma.svg";
import xcode from "../assets/icons/xcode.svg";
import winscp from "../assets/icons/winscp.jpeg";
import postman from "../assets/icons/postman.svg";
import git from "../assets/icons/git.svg";
import java from "../assets/icons/java.svg";
import swift from "../assets/icons/swift.svg";
import jenkins from "../assets/icons/jenkins.svg";
import jira from "../assets/icons/jira.svg";
import plSql from "../assets/icons/plSql.svg";
import apple from "../assets/icons/apple.svg";
import jasmine from "../assets/icons/jasmine.svg";
import sqlDeveloper from "../assets/icons/sqldevloper.svg";

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 500, // Animation occurs only once
    });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to a specific page
    navigate("/");

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [navigate]);

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="ar-main-heading container overflow-x-hidden">
      {/* main section */}
      <div className="row ar-section-padding">
        <div className="col-md-12 col-lg-7 col-xl-7 col-sm-12 padding-0" >
          <p className="hello-text">
            <FaRegHand className="ar-highlighted-text way-icon-size" /> Hey
            there, this is Amrit
          </p>
          <h1
            style={{ fontSize: "2rem" }}
            className="ar-highlighted-text ar-primary-text"
          >
            Senior Developer
          </h1>
          <div className="ar-spacer-hr-sm"></div>

          <h1 className="ar-primary-text" data-aos="fade-right">
            Bringing ideas to life with {""}
            <span className="ar-highlighted-text ">
              intuitive designs and seamless functionality.
            </span>
          </h1>
        </div>
        <div
          className="col-lg-5 col-xl-5 col-12 first-section-img-div"
          data-aos="fade-left"
        >
          <img src={mainImage} alt="creative Ideas" style={{height:'100%', width:'100%', objectFit:'contain'}}/>
        </div>
      </div>
      {/* main section ends */}
      {/* second section */}
      <div className="ar-spacer-hr-sm"></div>
      <div className="row inconsolata-family ">
        <div className="col-md-12 col-lg-6 col-xl-6 col-sm-12 ar-flex-between" style={{justifyContent: 'center'}}>
          {/* <FaSun /> */}
          {/* <div className="ar-add-seperator"></div> */}
          <div className="ar-flex ar-gap-10">
            <h2
              className="ar-highlighted-text counter"
              style={{ fontSize: "5rem" }}
            ></h2>
            <span
              className="bold-700 ar-light-grey ar-flex-center"
              style={{ fontSize: "2rem", lineHeight: "1" }}
            >
              Years Experience
            </span>
          </div>
          <div className="ar-flex ar-gap-10 web-view">
          <h2
              className="ar-highlighted-text counter"
              style={{ fontSize: "5rem" }}
            ></h2>
            <span
              className="bold-700 ar-light-grey ar-flex-center"
              style={{ fontSize: "2rem", lineHeight: "1" }}
            >
              Technologies Mastered
            </span>
          </div>

          {/* <FaMoon /> */}
        </div>
        <div
          className="col-md-12 col-lg-6 col-xl-6 col-sm-12 ar-flex ar-flex-align-center"
          data-aos="fade-left"
        >
          <span
            className="ar-simple-grey-color inconsolata-family second-section-text"
            data-aos-anchor-placement="top-center"
          >
            Leveraging UI design and backend skills to deliver precise,
            engaging, and accessible digital solutions that drive business
            success.
          </span>
        </div>
      </div>
      {/* section section ends */}
      <div className="row display-icons ar-align-items-center">
        <div
          className="col-md-12 col-lg-8 col-xl-8 col-sm-12 ar-flex-start footer-bottom-icons"
          style={{ justifyContent: "flex-start" }}
        >
          <a
            target="_blank"
            className="social-media-icon"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/amrit-raj-611447128/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-linkedin opacity-container-child"
            >
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
              <rect width="4" height="12" x="2" y="9"></rect>
              <circle cx="4" cy="4" r="2"></circle>
            </svg>
          </a>
          <a
            target="_blank"
            className="social-media-icon"
            rel="noopener noreferrer"
            href="https://github.com/amritaadeez"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-github opacity-container-child"
            >
              <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4"></path>
              <path d="M9 18c-4.51 2-5-2-7-2"></path>
            </svg>
          </a>
          <a
            target="_blank"
            className="social-media-icon"
            rel="noopener noreferrer"
            href="https://www.instagram.com/_amrit_itsaadeez/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-instagram opacity-container-child"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
              <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
            </svg>
          </a>
          <a
            target="_blank"
            className="social-media-icon"
            rel="noopener noreferrer"
            href="mailto: amrtrj68@email.com"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-mail opacity-container-child"
            >
              <rect width="20" height="16" x="2" y="4" rx="2"></rect>
              <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
            </svg>
          </a>
          <a
            target="_blank"
            className="social-media-icon"
            rel="noopener noreferrer"
            href=" https://x.com/amrtrj68"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-twitter opacity-container-child"
            >
              <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
            </svg>
          </a>
        </div>
        <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12">
          <div className="ar-flex-center ar-flex-end">
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              <button
                className="btn-flip transparent-bg-btn"
                data-back="About me"
                data-front="Know me better"
              ></button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="ar-spacer-hr-lg"></div>
      <div className="ar-spacer-hr-sm"></div>

      {/* expertise marquee section starts */}
      <div className="section key-strenths">
        <div className="row">
          <div className="col-12">
            <div data-aos="fade-right">
              <h5 className="ar-highlighted-text ar-section-heading">
                STRENGTHS & ATTRIBUTES
              </h5>
              {/* <div className="ar-spacer-hr-sm"></div> */}
            </div>
            <div
              width="100%"
              className="ar-flex-arround inconsolata-family bold-900 opacity-half marquee"
            >
              <span>Frontend</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>UI/UX</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Backend</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Collaboration</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Mentorship</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Leadership</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Innovation</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Reliability</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
              <span>Proactivity</span>
              <span>
                <FaBattleNet className="battle-net-icon" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ar-spacer-hr-lg">
        <div className="right-half-broder"></div>
      </div>
      {/* expertise marquee section ends */}
      {/* About me section starts */}
      <div className="section basic-info">
        <div className="row">
          <div
            className="col-md-12 col-sm-12 col-12 col-lg-7 col-xl-7"
            data-aos="fade-right"
          >
            <div data-aos="fade-right">
              <h5 className=" ar-highlighted-text ar-section-heading">
                A LITTLE ABOUT ME
              </h5>
              <div className="ar-spacer-hr-sm"></div>
            </div>

            <p className="inconsolata-family">
              I am a developer, live in Greater Noida, with 6 years of
              experience in both frontend and backend technologies. Currently, I
              work as a Senior Consultant at JK Tech, where I focus on Angular,
              Java, and Cypress for an insurance client, Oneshield. My role
              involves working on the overhaul of the Angular frontend to
              improve reliability, performance, and address memory leaks.
            </p>
            <p className="inconsolata-family">
              My expertise includes Angular, React.js, and mobile development
              with React Native, Ionic, iOS, and Swift. I am also experienced
              with the MEAN stack, Java, Spring Boot, Jenkins, WordPress, HTML,
              CSS, Bootstrap, and testing tools like Cypress and Karma/Jasmine.
              I am proficient in using Git, Jira, and GitLab for project
              management and am passionate about building scalable applications
              while staying current with industry trends.{" "}
            </p>
          </div>
          <div className="col-md-12 col-sm-12 col-12 col-lg-1 col-xl-1"></div>
          <div
            className="col-md-12 col-sm-12 col-12 col-lg-4 col-xl-4 bio-image"
            data-aos="fade-left"
          ></div>
          <div className="ar-spacer-hr-sm"></div>

          <div className="ar-flex-center ar-flex-end" data-aos="fade-up">
            <a
              className="button-anchor-tag"
              href="https://docs.google.com/document/d/1gAqTxUiHihiYp86hcoDklErNyZY4rBJpUCylYrAHaEA"
              target="_blank"
            >
              <button
                className="btn-flip transparent-bg-btn"
                data-back="Click to View"
                data-front="View Resume"
              ></button>
            </a>
          </div>
        </div>
      </div>

      <div className="ar-spacer-hr-lg"></div>

      <div className="section basic-info">
        <div className="row">
          <div
            className="col-md-12 col-sm-12 col-12 col-lg-4 col-xl-4 education-image"
            data-aos="fade-right"
          ></div>
          <div className="col-md-12 col-sm-12 col-12 col-lg-1 col-xl-1"></div>
          <div
            className="col-md-12 col-sm-12 col-12 col-lg-7 col-xl-7"
            data-aos="fade-left"
          >
            <div data-aos="fade-left">
              <h5 className="ar-highlighted-text ar-section-heading">
                EDUCATION
              </h5>
              <div className="ar-spacer-hr-sm"></div>
            </div>
            <div
              className="track"
              data-aos="fade-up"
              data-aos-delay="100"
            ></div>
            <p className="inconsolata-family" style={{ position: "relative" }}>
              <div data-aos="fade-in" data-aos-delay="200">
                <FaCircle className="track-point" />
              </div>
              <div data-aos="fade-left" data-aos-delay="300">
                <h6 className="ar-color-primary">
                  Masters of Technology - Executive M.Tech, Artificial
                  Intelligence and Data Science
                </h6>
                <span className="ar-simple-grey-color">
                  <i>
                    Indian Institute of Technology Patna | July 2024 - Present
                  </i>
                </span>
              </div>
            </p>
            <p className="inconsolata-family" style={{ position: "relative" }}>
              <div data-aos="fade-in" data-aos-delay="400">
                <FaCircle className="track-point" />
              </div>
              <div data-aos="fade-left" data-aos-delay="500">
                <h6 className="ar-color-primary">
                  Bachelor of Technology - B.Tech, Computer Science and
                  Engineering
                </h6>
                <span className="ar-simple-grey-color">
                  <i>
                    Dr. A.P.J. Abdul Kalam Technical University | August 2012 -
                    July 2016
                  </i>
                </span>
              </div>
            </p>
          </div>
        </div>
      </div>

      <div className="ar-spacer-hr-lg"></div>
      {/* about me section ends */}
      {/* Expertise section */}
      <div className="expertise-section">
        <div className="row">
          <div data-aos="fade-right">
            <h5 className="ar-highlighted-text ar-section-heading ">
              AREAS OF EXPERTISE
            </h5>
            <div className="ar-spacer-hr-sm"></div>
          </div>
          <div className="col-12 col-lg-4 col-xl-4 card-col" data-aos="fade-up">
            <div className="expertise-card">
              <h6 className="ar-color-primary ">Frameworks/Libraries</h6>
              <p className="card-content" style={{ minHeight: "80px" }}>
                Angular 6+, React JS, React Native, Ionic, PrimeNG, GSAP,
                Bootstrap, HTML/CSS
              </p>
              <div className="footer-icons" style={{ paddingBottom: "10px" }}>
                <img src={angular} title="angular" alt="icon-angular"/>
                <img src={react} title="react" alt="icon-react"/>
                <img src={ionic} title="ionic" alt="icon"/>
                <SiPrimeng />
                <img src={GSAP} title="GSAP" alt="icon"/>
                <img src={Bootstrap} title="bootstrap" alt="icon"/>
              </div>
              <div className="footer-icons">
                <img src={html} title="html" alt="icon"/>
                <img src={css} title="css" alt="icon"/>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-xl-4 card-col" data-aos="fade-up">
            <div className="expertise-card">
              <h6 className="ar-color-primary">Programming Languages</h6>
              <p className="card-content">
                JavaScript (ES6), TypeScript, Swift, Java 8
              </p>
              <div className="footer-icons">
                <img src={JavaScript} title="JavaScript" alt="icon"/>
                <img src={TypeScript} title="TypeScript" alt="icon"/>
                <img src={swift} title="Swift" alt="icon"/>
                <img src={java} title="Java" alt="icon"/>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-xl-4 card-col" data-aos="fade-up">
            <div className="expertise-card">
              <h6 className="ar-color-primary">Testing / QA</h6>
              <p className="card-content">Karma/Jasmine, Cypress</p>
              <div className="footer-icons">
                <img src={karma} title="Karma" alt="karma"/>
                <img src={jasmine} title="Jasmine" alt="jasmine"/>
                <img src={cypress} title="Cypress" alt="cypress"/>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-xl-4 card-col" data-aos="fade-up">
            <div className="expertise-card">
              <h6 className="ar-color-primary">Development Practices</h6>
              <p className="card-content">CI/CD, Git, Jira, Jenkins</p>
              <div className="footer-icons">
                <img src={devops} title="CI/CD" alt="icon"/>
                <img src={git} title="Git" alt="icon"/>
                <img src={jira} title="Jira" alt="icon"/>
                <img src={jenkins} title="Jenkins" alt="icon"/>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-xl-4 card-col" data-aos="fade-up">
            <div className="expertise-card">
              <h6 className="ar-color-primary">Miscellaneous Skills</h6>
              <p className="card-content">
                PL/SQL, Figma, SwiftUI, Storyboards
              </p>
              <div className="footer-icons">
                <img src={plSql} title="pl/sql" alt="icon"/>
                <img src={figma} title="pl" alt="icon"/>
                <img src={swift} title="Swift" alt="icon"/>
                <img src={apple} title="Apple" alt="icon"/>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-xl-4 card-col" data-aos="fade-up">
            <div className="expertise-card">
              <h6 className="ar-color-primary">Tools</h6>
              <p className="card-content">
                VSCode, Eclipse, Xcode, SQL Developer, WinSCP, Postman
              </p>
              <div className="footer-icons">
                <img src={vsCode} title="visual Studio" alt="icon"/>
                <img src={eclipse} title="Eclipse" alt="icon"/>
                <img src={xcode} title="Xcode IDE" alt="icon"/>
                <img src={sqlDeveloper} title="SQL Developer" alt="icon"/>
                <img src={winscp} title="winscp" alt="icon"/>
                <img src={postman} title="postman" alt="icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ar-spacer-hr-lg"></div>

      <div className="section basic-info">
        <div className="row">
          <div
            className="col-md-12 col-sm-12 col-12 col-lg-4 col-xl-4 professional-laptop-image padding-left-4"
            data-aos="fade-right"
          ></div>
          <div className=" col-md-12 col-sm-12 col-12 col-lg-1 col-xl-1"></div>
          <div
            className="col-md-12 col-sm-12 col-12 col-lg-7 col-xl-7"
            data-aos="fade-left"
          >
            <div data-aos="fade-left">
              <h5 className=" ar-highlighted-text ar-section-heading">
                PROFESSIONAL SNAPSHOT
              </h5>
              <div className="ar-spacer-hr-sm"></div>
            </div>
            <div
              className="track"
              data-aos="fade-up"
              data-aos-delay="100"
            ></div>
            <p className="inconsolata-family" style={{ position: "relative" }}>
              <div data-aos="fade-in" data-aos-delay="200">
                {" "}
                <FaCircle className="track-point" />{" "}
              </div>
              <div data-aos="fade-left" data-aos-delay="300">
                <h6 className="ar-color-primary">
                  Current Role: Senior Consultant at JK Technosoft
                </h6>
                <span className="ar-simple-grey-color ">
                  <i>July 2022 - Present</i>
                </span>{" "}
                <br />
                Revamped an Angular application to enhance performance and user
                experience. Developed robust backend features with Java,
                improving application stability and functionality for insurance
                solutions.{" "}
              </div>
            </p>
            <p className="inconsolata-family" style={{ position: "relative" }}>
              <div data-aos="fade-in" data-aos-delay="400">
                {" "}
                <FaCircle className="track-point" />{" "}
              </div>
              <div data-aos="fade-left" data-aos-delay="500">
                <h6 className="ar-color-primary">
                  Previous Role: Software Engineer at Eroute Technologies Pvt
                  Ltd
                </h6>
                <span className="ar-simple-grey-color">
                  <i>November 2019 - July 2022</i>
                </span>{" "}
                <br />
                Designed and implemented secure iOS features, including Keychain
                and UPI screens. Integrated key financial functionalities such
                as BBPS, AEPS, prepaid recharges, and money transfers using
                Angular and React.js.
              </div>
            </p>
            <p className="inconsolata-family" style={{ position: "relative" }}>
              <div data-aos="fade-in" data-aos-delay="600">
                {" "}
                <FaCircle className="track-point" />{" "}
              </div>
              <div data-aos="fade-left" data-aos-delay="700">
                <h6 className="ar-color-primary">
                  Early Career: Software Developer at mLabs Research
                </h6>
                <span className="ar-simple-grey-color ">
                  <i>September 2018 - October 2019</i>
                </span>{" "}
                <br />
                Developed a PG preparation app using Angular 6 and the MEAN
                stack. Focused on creating responsive, user-friendly interfaces
                and applying effective UI/UX principles to enhance the
                educational experience.{" "}
              </div>
            </p>
          </div>
        </div>
      </div>

      <div className="ar-spacer-hr-lg"></div>
      <div className="ar-spacer-hr-lg"></div>

      <div className="section testimonials-info">
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-4">
            <div data-aos="fade-right">
              <h5 className="ar-highlighted-text ar-section-heading">
                TESTIMONIALS
              </h5>
              {/* <div className="ar-spacer-hr-sm"></div> */}

              <h1 className="inconsolata-family">What others say</h1>
              <p className="inconsolata-family ar-light-grey">
                I've worked with some amazing people over the years, here is
                what they have to say about me.
              </p>
            </div>
            <div className="ar-spacer-hr-sm"></div>
          </div>
          <div
            className="col-12 col-lg-8 col-xl-8 "
            style={{ textAlign: "left" }}
            data-aos="fade-down"
          >
            <p className="inconsolata-family" >
              <Carousel />
            </p>
          </div>
          <div className="ar-spacer-hr-sm"></div>
        </div>
      </div>
      {showScrollToTop && (
        <button className="ar-info transparent-bg-btn scroll-top-btn web-view" onClick={scrollToTop}>
          <button className="btn btn-icon"> <FaArrowUp /> </button>
        </button>
      )}
    </div>
  );
}

export default Home;
