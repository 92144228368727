import React from "react";
import "../styles/Skills.css";

const Skills = () => {
  return (
    <div class="skills-bar">
      {/* <div className="basic-info"> */}
        <div class="bar">
          <div class="info">
            <span>FrontEnd Experties</span>
          </div>
          <div class="progress-line">
            <span class="frontend"></span>
          </div>
        </div>
        
        <div class="bar">
          <div class="info">
            <span>Mobile Apps Experties</span>
          </div>
          <div class="progress-line">
            <span class="mobileapps"></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>UI/UX Experties</span>
          </div>
          <div class="progress-line">
            <span class="ui-ux"></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>Backend Development</span>
          </div>
          <div class="progress-line">
            <span class="backend"></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>Testing</span>
          </div>
          <div class="progress-line">
            <span class="testing"></span>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default Skills;
