import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const apiService = {
  // Method for submitting the contact form
  submitContactForm: async (formData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/contact`, formData);
      return response.data;
    } catch (error) {
      console.error('Error submitting contact form:', error);
      throw error;
    }
  },

  getUsers:async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`);
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  // Add more API methods as needed
};

export default apiService;
